$(document).ready(function(){
	$(window).load(function(){
		// VARIABLESmin-height

		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$ajaxUrl = $('.ajaxlocation').data('ajaxlocation'),
		$mainWrapper = $('.wrapper-holder');
		// INITIATIONS
		AOS.init();
		// CUSTOM FUNCTIONS
		smoothScroll();
		carouselAnimation();
		tableClass();
		mobileLayout();
		stratAjax($ajaxUrl);
		//wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
		responsiveSlick();
		fixMenu();
		active_state();
		$('.loader-overlay').slideUp(50);

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		//wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
/*if( Modernizr.mq('(min-width: 1200px)') ) {
	equalizer();
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	equalizer();
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	equalizer();
}
else{
}*/

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/

function pre( $object ){
	console.log( $object );
}
function smoothScroll(){
	$(".down-arrow").click(function() {
	    $('html, body').animate({
	        scrollTop: $(".front-site").offset().top
	    }, 1000);
	});
}
function active_state() {
  var $current_page = $('#current-page').val();
  if($current_page && $current_page == 'community-single') {
    $('.community-menu').addClass('current-menu-item');
  }
}
function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}
function stratAjax($ajaxUrl){
			$(document).on('click', '.email-btn',function(e){
						$(".remodal").addClass("email-modal");
				$(".remodal").on("opened", function() {
/*					setTimeout(function(){
					}, 300);	*/	
				});
				$(".remodal").on("closed", function() {
						$(".remodal").removeClass("email-modal");
/*					setTimeout(function(){
					}, 300);	*/	
				});
			});
			$(document).on('click', '.strategy-categories-btn',function(e){
			$('.strategy-categories-btn').removeClass('active');
			$(this).addClass('active');
			var productid = $(this).data('termname');
			var termid 	= $(this).data('termid');
			var $loader = $('.load-bar');
			$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'strat_select',
                    post_id : productid,
                    term_id : termid,
                },
                     beforeSend: function(){
                     	$loader.fadeIn('fast');
                     	$('.strategy-holder').fadeOut('slow');
                     },
                     success: function(response) {
                     	$loader.fadeOut('slow');
                     	$('.strategy-holder').fadeIn('slow');
                         var result  = response.output;
                         $('.strategy-holder').html(result);
                    }
            });
		});
		$(document).on('click', '.strategy-data-title',function(e){
			$('.strategy-data-title').removeClass('active');
			$(this).addClass('active');
			var termchild = $(this).data('child');
			var $loader = $('.load-bar');
			$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'ajax_child',
                    term_id : termchild,
                },
                     beforeSend: function(){
                     	// $loader.fadeIn('fast');
                     	// $('.strategy-holder').fadeOut('slow');
                     },
                     success: function(response) {
                     	// $loader.fadeOut('slow');
                     	// $('.strategy-holder').fadeIn('slow');
                         var result  = response.output;
                       $('.sliderControl').html(result);
                    }
            });
		});
		$(document).on('change', '.strategy-data-select',function(e){
			//$('.strategy-data-title').removeClass('active');
			$(this).addClass('active');
			var termchild = $(this).find(':selected').data('child')
			var $loader = $('.load-bar');
			$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'ajax_child',
                    term_id : termchild,
                },
                     beforeSend: function(){
                     	// $loader.fadeIn('fast');
                     	// $('.strategy-holder').fadeOut('slow');
                     },
                     success: function(response) {
                     	// $loader.fadeOut('slow');
                     	// $('.strategy-holder').fadeIn('slow');
                         var result  = response.output;
                       $('.sliderControl').html(result);
                    }
            });
		});
		$(document).on('click', '.strategy-holder-image, .front-site-post-content',function(e){
			var postid = $(this).data('pageid');
			var $loader = $('.footer-loader');
			$.ajax({
                url : $ajaxUrl,
                type : 'POST',
                dataType: 'json',
                data : {
                    action : 'strat_post',
                    post_id : postid,
                },
                     beforeSend: function(){
                     	$('.loader-overlay').fadeIn(50);
                     	// $('.strategy-holder').fadeOut('slow');
                     },
                     success: function(response) {
                     	$('.loader-overlay').fadeOut(1000);
                         var result  = response.output;
                         $('.strategy-data').html(result);
                    }
            });
		});
}
function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight, $navHeight, $footerHeight ){
	var $pgHeight, $nvHeight, $ftHeight;
	
	if( $pageHeight ) {
		$pgHeight = $pageHeight;
	} else {
		$pgHeight = 0;
	}

	if( $navHeight ) {
		$nvHeight = $navHeight;
	} else {
		$nvHeight = 0;
	}

	if( $footerHeight ) {
		$ftHeight = $footerHeight;
	} else {
		$ftHeight = 0;
	}

	$('.wrapper-holder').css({
		'min-height': $pgHeight - $nvHeight,
		'margin-top': $nvHeight,
		'padding-bottom': $ftHeight
	});
	$('.main-layout').css({
		'min-height': $pgHeight - ( $nvHeight + $ftHeight ),
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ 52 +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}


function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.client-slider').slick({
	  autoplay: $('default-content').data('autoplay'),
	  dots: true,
	  infinite: false,
	  speed: $('default-content').data('duration'),
	  fade: $('default-content').data('transition'),
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: true,
	  dots: false,
	  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
	  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
	});
	$(".remodal").on("opened", function() {
		setTimeout(function(){
			//$("body,html").css("overflow",'hidden');
			sliderStrategy();
		}, 100);		
	});
	$(".remodal").on("closed", function() {
		setTimeout(function(){
			//$("body,html").css("overflow",'');
		}, 300);		
	});
}
function sliderStrategy(){
	$('.strategy-data-slider').slick({
		  autoplay: true,
		  infinite: false,
		  speed: 500,
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  arrows: false,
		  dots: true,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-chevron-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-chevron-right"></button>',
		});
		$(document).on('change', '.strategy-data-select',function(e){
			$('.strategy-data-title').removeClass('active');
	        $(this).addClass('active');
	        $('.strategy-data-bottom').hide();
	        $('.strategy-data-bottom.div'+$(this).find(':selected').attr('target')).show();
	    });
		$('.strategy-data-title').click(function(){
			$('.strategy-data-title').removeClass('active');
	        $(this).addClass('active');
	        $('.strategy-data-bottom').hide();
	        $('.strategy-data-bottom.div'+$(this).attr('target')).show();
	    });
}
function equalizer(){
	var maxHeight = 0;
	$(".equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".equalize").height(maxHeight);

	var productHeight = 0;
	$(".hp-equalize").each(function(){
	   if ($(this).height() > productHeight) { productHeight = $(this).height(); }
	});
	$(".hp-equalize").height(productHeight);
}
function fixMenu(){
	var n = $(".main-header");
	var width = $(window).width();
	$(window).scroll(function() {
	    if( $(this).scrollTop() > 265 )  {
	        n.addClass("fixeNav");
	         if (width > 1200){
			    $('.header-top, .header-content').addClass("d-none");
			    $('.default-content').addClass('marginscroll');
			    $('.home').addClass('marginAdd');
			 }
	        n.slideDown('fast');
	    } 
	    else {
	        n.removeClass("fixeNav");
	        if (width > 1200){
			    $('.header-top, .header-content').removeClass("d-none");
			    $('.default-content').removeClass('marginscroll');
			    $('.home').removeClass('marginAdd');
			}
	    }
	});
}
function caretMenu(){
	$('.desktop-menu .nav .menu li').each( function(){
		var menu = $(this);
		if( menu.find('.sub-menu').length > 0 ) {
			menu.find('a').first().append('<span class="fas fa-angle-down"></span>');
		}
	});
}

// IOS INPUT BUG FIX
(function iOS_CaretBug() {
	var ua = navigator.userAgent,
	scrollTopPosition,
	iOS = /iPad|iPhone|iPod/.test(ua),
	iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
 	if ( iOS && iOS11 ) {

		$(document.body).on('show.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

     $(document.body).on('hide.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
     });
   }
})();